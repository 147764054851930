import { routeConstants, commonRoutesConstants } from "services/keyConstants";

const baseUrl = `/${routeConstants.INTERNAL_COMMUNICATION}`;

export const CREATE_INTERNAL_COMMUNICATION = `${baseUrl}/`;
export const GET_ALL_DATA_BY_TYPE = `${baseUrl}/get-dynamic`;
export const GET_BY_ID = `${baseUrl}/`;

export const GET_BY_YEAR = `${baseUrl}/${commonRoutesConstants.YEAR_LIST}`;

export const UPDATE_SPECIFIC = `${baseUrl}/update-specific`;

export const DOWNLOAD = `${baseUrl}/download`;
